<template>
	<div class="imageToWords">
		<img
			class="word"
			:src="`/img/words/${infos.choices[idx]}.jpg`"
			v-for="(choice, idx) in infos.choices"
			:key="idx"
			@click="onAnswer(idx)"
			:ref="setBtnRef"
		>
		<div ref="word" class="pronounce">
			{{ infos.kr }}
		</div>
	</div>
</template>

<script>
import { gsap, Quad, TimelineMax } from 'gsap/all'
import { Howl } from 'howler'

export default {
	name: 'ExerciceImageToWords',
	emits: ['done'],
	props: {
		infos: {
			type: Object,
			required: true
		}
	},
	data () {
		return {
			btnRefs: []
		}
	},

	mounted () {
		this.transitionIn()
	},
	beforeUpdate () {
		this.btnRefs = []
	},
	methods: {
		setBtnRef (el) {
			if (el) {
				this.btnRefs.push(el)
			}
		},
		onAnswer (idx) {
			if (this.infos.choices[idx] === this.infos.translate) {
				gsap.to(this.btnRefs[idx], { background: '#ffff00', scale: 1.1, onComplete: () => { this.next() } })
			} else {
				gsap.to(this.btnRefs[idx], { x: -10, ease: 'xEase', duration: 0.4 })
			}
		},
		playSound () {
			var sound = new Howl({
				src: [`/audio/words/${this.infos.translate}.mp3`]
			})
			sound.play()
		},
		next () {
			this.$emit('done')
		},
		transitionIn (cb) {
			gsap.from(this.$refs.word, { opacity: 0, scale: 0.8 })
			const tl = new TimelineMax({ delay: 0.3 })
			tl.staggerFromTo(this.btnRefs, 0.5, { opacity: 0, scale: 0.8 }, { opacity: 1, scale: 1, ease: Quad.easeOut, duration: 0.5 }, 0.2)
		},
		transitionOut (cb) {
			gsap.to(this.$refs.word, { delay: 0.3, opacity: 0, scale: 0.8, onComplete: () => { if (cb) { cb() } } })
			const tl = new TimelineMax({ delay: 0.05 })
			tl.staggerTo(this.btnRefs, 0.5, { opacity: 0, x: 80, ease: Quad.easeOut }, 0.1, '+=0')
		}
	}
}
</script>

<style lang="stylus" scoped>
	.imageToWords
		user-select none
		position absolute
		top calc(50% - 80px)
		left 50%
		transform: translate3d(-50%,-50%,0)
		width calc(100% - 80px)

		.flex
			display: flex
			flex-wrap: wrap
			flex-direction row
			align-items center
			align-content center
			justify-content: center
			-webkit-box-direction: normal
			-webkit-box-pack: center
			margin-bottom 60px

			div, img
				align-self: center
		.pronounce ::v-deep
			font-size: 40px
			margin-right 20px
			color #fff
			position relative
			display inline-block

			&:after
				content ""
				display block
				position absolute
				bottom -5px
				left 0
				width 100%
				height 1px
				background #fff

		.word
			padding 10px
			font-size: 3em
			background-color: #fff
			border-radius: 50%
			display: inline-block
			width 120px
			height 120px
			margin-bottom 20px

</style>
