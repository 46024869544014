<template>
	<div class="imageToWords">
		<img
			class="word" ref="word"
			:src="`/img/words/${infos.translate}.jpg`"
			@click="playSound"
		>
		<Button class="choice" v-for="(choice, idx) in infos.choices" :key="idx" @click="onAnswer(idx)" :ref="setBtnRef">
			{{ choice }}
		</Button>
	</div>
</template>

<script>
import { gsap, Quad, TimelineMax } from 'gsap/all'
import { Howl } from 'howler'

export default {
	name: 'ExerciceImageToWords',
	emits: ['done'],
	props: {
		infos: {
			type: Object,
			required: true
		}
	},
	data () {
		return {
			btnRefs: []
		}
	},

	mounted () {
		this.transitionIn()
	},
	beforeUpdate () {
		this.btnRefs = []
	},
	methods: {
		setBtnRef (el) {
			if (el) {
				this.btnRefs.push(el.$el)
			}
		},
		onAnswer (idx) {
			if (this.infos.choices[idx] === this.infos.kr) {
				gsap.to(this.btnRefs[idx], { background: '#ffff00', scale: 1.1, onComplete: () => { this.next() } })
			} else {
				gsap.to(this.btnRefs[idx], { x: -10, ease: 'xEase', duration: 0.4 })
			}
		},

		playSound () {
			var sound = new Howl({
				src: [`/audio/words/${this.infos.translate}.mp3`]
			})
			sound.play()
		},
		next () {
			this.$emit('done')
		},
		transitionIn (cb) {
			gsap.from(this.$refs.word, { opacity: 0, scale: 0.8 })
			const tl = new TimelineMax({ delay: 0.3 })
			tl.staggerFromTo(this.btnRefs, 0.5, { opacity: 0, x: -50 }, { opacity: 1, x: 0, ease: Quad.easeOut, duration: 0.5 }, 0.2)
		},
		transitionOut (cb) {
			gsap.to(this.$refs.word, { delay: 0.3, opacity: 0, scale: 0.8, onComplete: () => { if (cb) { cb() } } })
			const tl = new TimelineMax({ delay: 0.05 })
			tl.staggerTo(this.btnRefs, 0.5, { opacity: 0, x: 80, ease: Quad.easeOut }, 0.1, '+=0')
		}
	}
}
</script>

<style lang="stylus" scoped>
	.imageToWords
		user-select none
		position absolute
		top calc(50% - 80px)
		left 50%
		transform: translate3d(-50%,-50%,0)
		width calc(100% - 80px)

		.flex
			display: flex
			flex-wrap: wrap
			flex-direction row
			align-items center
			align-content center
			justify-content: center
			-webkit-box-direction: normal
			-webkit-box-pack: center
			margin-bottom 60px

			div, img
				align-self: center

		.word
			padding 10px
			font-size: 3em
			background-color: #fff
			border-radius: 50%
			display: inline-block
			width 120px
			height 120px
			margin-bottom 20px

</style>
