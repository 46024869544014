<template>
	<div class="stage">
		<component
			v-if="slides!=null"
			:is="slide"
			ref="slide"
			:infos="slides[slideIdx]"
			:key="slideIdx"
			@done="onNext"
		/>
		<Success v-if="isCompleted" @finish="onEnd" />
	</div>
</template>

<script>

import router from '@/router'
import TeachingVocabulary from './vocabulary/TeachingVocabulary'
// import VocabularyReview from './vocabulary/VocabularyReview'
import WordChallenge from './vocabulary/WordChallenge'
import UserData from '@/data/UserData'
import Success from '@/components/Success'
import ExerciceImageToWords from './vocabulary/ExerciceImageToWords.vue'
import ExerciceWordToImages from './vocabulary/ExerciceWordToImages.vue'

export default {
	name: 'Stage',
	components: {
		Success
	},
	emits: ['completed'],
	props: {
		jsonID: {
			type: String,
			default: 'hangul01.json'
		}
	},
	data () {
		return {
			isCompleted: false,
			slideIdx: 0,
			btnRefs: [],
			slides: null
		}
	},
	mounted () {
		fetch('/data/' + this.$route.params.id + '.json')
			.then((response) => response.json()).then((json) => {
				this.slides = json
			})
	},
	computed: {
		slide () {
			switch (this.slides[this.slideIdx].type) {
			case 'learn-word':
				return TeachingVocabulary
			case 'exercice-image-to-word':
				return ExerciceImageToWords
			case 'exercice-word-to-image':
				return ExerciceWordToImages
			default:
				return WordChallenge
			}
		}
	},
	methods: {
		onEnd () {
			router.go(-1)
		},
		transitionIn () {
		},
		transitionOut () {
		},
		onNext () {
			this.nextSlide()
		},
		nextSlide () {
			this.$refs.slide.transitionOut(() => {
				if (this.slideIdx === this.slides.length - 1) {
					UserData.finishStage(this.$route.params.id)
					this.isCompleted = true
				} else {
					this.slideIdx++
					this.$refs.slide.transitionIn()
				}
			})
		}
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.transitionIn()
		})
	},
	beforeRouteLeave (to, from, next) {
		this.transitionOut()
		setTimeout(next, 400)
	}
}
</script>
<style lang="stylus" scoped>
.stage
	min-height 100%
	height 100%
</style>
