<template>
	<div class="WordChallenge">
		<div class="letter" ref="letter">
			{{ infos.letter }}
		</div>
		<p ref="instruction">
			Select the right Answer
		</p>
		<Button class="choice" v-for="(choice, idx) in infos.choices" :key="idx" @click="onAnswer(idx)" :ref="setBtnRef">
			{{ choice }}
		</Button>
	</div>
</template>

<script>
import { gsap, Quad, TimelineMax } from 'gsap/all'
import { CustomEase } from '@/lib/gsap-bonus/CustomEase'
import { CustomWiggle } from '@/lib/gsap-bonus/CustomWiggle'
gsap.registerPlugin(CustomEase, CustomWiggle)
CustomWiggle.create('xEase', { type: 'uniform', wiggles: 8 })

export default {
	name: 'WordChallenge',
	emits: ['done'],
	props: {
		infos: {
			type: Object,
			default: () => {
				return {
					letter: 'A',
					choices: ['A', 'B', 'C', 'D'],
					answer: 'A'
				}
			}
		}
	},
	data () {
		return {
			btnRefs: []
		}
	},
	mounted () {
		this.transitionIn()
	},
	beforeUpdate () {
		this.btnRefs = []
	},
	methods: {
		setBtnRef (el) {
			if (el) {
				this.btnRefs.push(el.$el)
			}
		},
		playSound () {
			console.log('sound On')
		},
		next () {
			this.$emit('done')
		},
		onAnswer (idx) {
			if (this.infos.choices[idx] === this.infos.answer) {
				gsap.to(this.btnRefs[idx], { background: '#ffff00', scale: 1.1, onComplete: () => { this.next() } })
			} else {
				gsap.to(this.btnRefs[idx], { x: -10, ease: 'xEase', duration: 0.4 })
			}
		},
		transitionOut (cb) {
			gsap.to(this.$refs.instruction, { delay: 0.2, opacity: 0, x: 20 })
			gsap.to(this.$refs.letter, { delay: 0.3, opacity: 0, scale: 0.8, onComplete: () => { if (cb) { cb() } } })
			const tl = new TimelineMax({ delay: 0.05 })
			tl.staggerTo(this.btnRefs, 0.5, { opacity: 0, x: 80, ease: Quad.easeOut }, 0.1, '+=0')
		},
		transitionIn (cb) {
			gsap.from(this.$refs.letter, { opacity: 0, scale: 0.8 })
			gsap.from(this.$refs.instruction, { delay: 0.2, opacity: 0, x: -20, onComplete: () => { if (cb) { cb() } } })
			const tl = new TimelineMax({ delay: 0.3 })
			tl.staggerFromTo(this.btnRefs, 0.5, { opacity: 0, x: -50 }, { opacity: 1, x: 0, ease: Quad.easeOut, duration: 0.5 }, 0.2)
		}
	}
}
</script>

<style lang="stylus" scoped>
	.WordChallenge
		position absolute
		top calc(50% - 80px)
		left 50%
		transform: translate3d(-50%,-50%,0)
		width calc(100% - 80px)
		max-width	400px

		p
			font-size 16px
			color #fff

		.letter
			padding 30px
			font-size: 3em
			background-color: #fff
			border-radius: 50%
			display: inline-block
			width 60px
			height 60px
			margin-bottom 20px

		.choice
			border-radius: 15px
			border 1px solid #fff
			padding 20px
			margin-bottom 20px

</style>
